
import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ToastComponent } from "../toast/toast.component";
import { ToastService } from 'src/app/services/toast.service';
import { Toast } from 'src/app/model/toasts';
import { AUTO_STYLE, animate, sequence, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-toast-host',
  standalone: true,
  templateUrl: './toast-host.component.html',
  styleUrls: ['./toast-host.component.scss'],
  imports: [ToastComponent],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(120%)', height: '0', margin: '0', opacity: 0 }),
        sequence([
          animate('150ms ease-in', style({ border: AUTO_STYLE, height: AUTO_STYLE, margin: AUTO_STYLE })),
          animate('450ms cubic-bezier(.26,.62,.47,1.29)', style({ transform: 'translateX(0%) scale(1)', opacity: 1 })),
        ])
      ]),
      transition(':leave', [
        style({ 'transform': AUTO_STYLE }),
        sequence([
          animate('450ms cubic-bezier(.73,-0.54,.91,.6)', style({ transform: 'translateX(120%) scale(0)', opacity: 0 })),
          animate('250ms 50ms ease-out', style({ height: '0', margin: '0' }))
        ])
      ])
    ])
  ]
})
export class ToastHostComponent implements OnInit {
  constructor(
    private toastService: ToastService
  ) { }

  private ngUnsubscribe = new Subject<void>();

  toasts: Toast[] = [];

  ngOnInit(): void {
    this.listen();
  }

  private listen(): void {
    this.toastService.getToasts()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(toast => {
        this.toasts.push(toast);
      });
  }

  close(toast: Toast): void {
    const index = this.toasts.indexOf(toast);
    if (index >= 0) {
      this.toasts.splice(index, 1);
    }
  }
}
