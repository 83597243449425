import { Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHover]',
  standalone: true
})
export class HoverDirective {

  @Output() mouseEnter: EventEmitter<Event> = new EventEmitter();
  @Output() mouseLeave: EventEmitter<Event> = new EventEmitter();

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.listen(this.el.nativeElement, 'mouseenter', (event) => {
      this.mouseEnter.emit(event);
    });

    this.renderer.listen(this.el.nativeElement, 'mouseleave', (event) => {
      this.mouseLeave.emit(event);
    });
  }

}
