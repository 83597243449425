import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HoverDirective } from 'src/app/directives/hover.directive';
import { Toast, ToastType } from 'src/app/model/toasts';
import { isDefined } from 'src/app/util/utils';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, HoverDirective],
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('fadeOut', [
      transition(':leave', [
        animate('500ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})

export class ToastComponent implements OnInit {
  @Input({ required: true }) toast!: Toast;
  @Output() close = new EventEmitter<void>();

  public ToastType = ToastType;

  colorClasses: string = '';
  iconClass: string = '';

  holdTimer: boolean = false;
  progress: number = 100;
  timerActive: boolean = true;
  timer: NodeJS.Timeout | undefined;

  ngOnInit(): void {
    if (this.toast.options?.duration === null || this.toast.options?.duration === undefined || this.toast.options?.duration > 0) {
      this.startProgressTimer();
    }
    else {
      this.timerActive = false;
    }
    this.setToastTypeClasses();
  }

  private startProgressTimer(): void {
    const duration = isDefined(this.toast.options?.duration) && typeof this.toast.options?.duration === 'number' ? this.toast.options!.duration! : 5000;
    const iterations = (duration / 1000) >= 10 ? 1000 : Math.ceil(duration / 10);
    const interval = Math.ceil(duration / iterations);

    const decreaseBy = 100 / iterations;

    this.timer = setInterval(() => {
      if (!this.holdTimer) {
        this.progress -= decreaseBy;
      }

      if (this.progress <= 0) {
        this.closeClick();
      }
    }, interval);
  }

  public closeClick(): void {
    this.stopTimer();
    this.close.emit();
  }

  public stopTimer(): void {
    this.timerActive = false;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  private setToastTypeClasses(): void {
    switch (this.toast.type) {
      case ToastType.ERROR:
        this.colorClasses = 'bg-red-800 text-red-200';
        this.iconClass = 'bi-x-circle-fill';
        break;
      case ToastType.WARNING:
        this.colorClasses = 'bg-orange-700 text-orange-200';
        this.iconClass = 'bi-exclamation-circle-fill';
        break;
      case ToastType.SUCCESS:
        this.colorClasses = 'bg-green-800 text-green-200';
        this.iconClass = 'bi-check-circle-fill';
        break;
      case ToastType.INFO:
        this.colorClasses = 'bg-blue-800 text-blue-200';
        this.iconClass = 'bi-info-circle-fill';
        break;
      case ToastType.NEUTRAL:
      default:
        this.colorClasses = 'bg-gray-500 text-gray-200';
        this.iconClass = 'bi-chat-left';
        break;
    }
  }
}
