<div (click)="stopTimer()"
    appHover (mouseEnter)="holdTimer = true" (mouseLeave)="holdTimer = false"
    class="relative flex overflow-hidden items-center w-full max-w-screen-md ml-2 p-3 rounded-md shadow  bg-gray-50 text-gray-800 border border-gray-400"
    role="alert">
    <div class="toast-icon" [ngClass]="colorClasses">
        <i class="bi" [ngClass]="iconClass"></i>
    </div>

    <div class="ml-3 mr-2 text-sm font-normal">
        {{ toast.message }}
    </div>
    <button type="button" (click)="closeClick()"
        class="close-btn"
        aria-label="Close">
        <div>
            <i class="bi bi-x text-xl"></i>
        </div>
    </button>
    @if (timerActive)
    {
        <div [@fadeOut]>
            <div class="absolute h-0.5 inset-x-0 bottom-1 z-10 opacity-20" [ngClass]="colorClasses"></div>
            <div class="absolute h-0.5 left-0 bottom-1 z-20" [style.width]="progress + '%'" [ngClass]="colorClasses"></div>
        </div>
    }
</div>
